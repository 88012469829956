import React from "react";

function AEMFormServices() {
	return (
		<section className="section section--services section--wave-bg --section-mlarge-tb-padding">
			<div className="container">
				<div className="container__row --flex-center --section-small-tb-padding">
					<div className="container__col-md-4 container__col-xs-12 --section-large-tb-padding">
						<h2 className="container__heading">Our AEM Form services</h2>
						<div className="container__services">
							<div className="container__row">
								<div className="container__col-md-2 container__col-xs-2">
									<img src="../../solutions/checkmark.png" alt="Hashout" />
								</div>
								<div className="container__col-md-10 container__col-xs-10">
									<h5 className="container__sub-heading">
										Assessment of existing forms and identifying the suitability
										of AEM Forms
									</h5>
								</div>
							</div>
							<div className="container__row">
								<div className="container__col-md-2  container__col-xs-2">
									<img src="../../solutions/checkmark.png" alt="Hashout" />
								</div>
								<div className="container__col-md-10  container__col-xs-10">
									<h5 className="container__sub-heading">
										Design, Develop authoring form components along with 3rd
										party integrations for data, payment gateway, etc.
									</h5>
								</div>
							</div>
							<div className="container__row">
								<div className="container__col-md-2  container__col-xs-2">
									<img src="../../solutions/checkmark.png" alt="Hashout" />
								</div>
								<div className="container__col-md-10  container__col-xs-10">
									<h5 className="container__sub-heading">
										Maintenance and recurring improvements of the existing
										implementation.
									</h5>
								</div>
							</div>
						</div>
					</div>
					<div className="container__col-md-7 container__col-xs-12 container__col-md-offset-1 --flex-center">
						<img
							className="container__img --bg-absolute-right"
							src="../../solutions/platforms/aem-forms-services.png"
							alt="Jamstack"
						/>
					</div>
				</div>
			</div>
			{/* <img className="section__wave" src="../../solutions/wave.svg" alt="Hashout" />  */}
		</section>
	);
}

export default AEMFormServices;
