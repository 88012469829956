import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function AdobeFormsBanner() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--banner">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 --section-large-tb-padding">
            <h1 className="container__heading container__heading--platform">
              Adobe Experience Manager Forms{" "}
            </h1>
            <img
              className="container__logo"
              src="../../solutions/platforms/solution-partner.png"
              alt="Adobe Experience Manager"
            />
            <h2 className="container__sub-heading container__sub-heading--platform">
              Design, approve, and publish centrally managed forms
            </h2>
            <h6 className="container__content">
              Hashout is an Adobe Solutions Partner with extensive experience in
              helping Governments and Enterprises transform their customer
              experiences and backend through modernized forms.
            </h6>
            <a
              className="container__link"
              href="https://experienceleague.adobe.com/docs/experience-manager-65/forms/getting-started/introduction-aem-forms.html?lang=en"
              target="_blank"
            >
              EXPLORE AEM FORMS USE CASES
            </a>
            <button
              className="button container__button container__button--platform button--yellow"
              onClick={openModal}
            >
              Get 40 hrs free consultation!
            </button>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/platforms/aem-forms.png"
              alt="Web Experiences"
            />
          </div>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="Adobe-Forms-Banner"
        page="Adobe-Forms"
       
      />
    </section>
  );
}

export default AdobeFormsBanner;
