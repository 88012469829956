import React from "react";

function AdobeFormsTools() {
  return (
    <section className="section section--tools section--key-features section--bg-light-blue section--ldots-bg --section-normal-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">Tools and Accelerators</h2>
            <div className="container__row">
              <div className="container__col-md-7">
                <p className="container__content">
                  Our continously efforts to build common repeatable solutions
                  as tools ensures providing faster time to market and redueced
                  effor on our engagements. These tools are also available to be
                  distrubted stand-alone, reach out to us to learn more.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container__row --flex-center">
          <div className="container__col-md-3 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/key-features/adaptive-forms.png"
                alt="Adaptive Forms"
              />
              <a className="">
                <h5 className="card__title">Adaptive Forms</h5>
              </a>
            </div>
          </div>
          <div className="container__col-md-3 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/key-features/drag-drop.png"
                alt="rag and drop Form Layout builder"
              />
              <a>
                <h5 className="card__title">Drag and drop Form Layout builder</h5>
              </a>
            </div>
          </div>
          <div className="container__col-md-3 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/key-features/digital-workflows.png"
                alt="Efficiency using Digital Workflows in the backend"
              />
              <a>
                <h5 className="card__title">
                  Efficiency using Digital Workflows in the backend
                </h5>
              </a>
            </div>
          </div>
          <div className="container__col-md-3 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/key-features/adobe-sign.png"
                alt="Integration with Adobe Sign"
              />
              <a>
                <h5 className="card__title">Integration with Adobe Sign</h5>
              </a>
            </div>
          </div>
          <div className="container__col-md-3 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/key-features/adobe-analytics.png"
                alt="Comprehensive Metrics using Adobe Analytics"
              />
              <a>
                <h5 className="card__title">
                  Comprehensive Metrics using Adobe Analytics
                </h5>
              </a>
            </div>
          </div>
          <div className="container__col-md-3 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/key-features/cloud-native.png"
                alt="Cloud Native"
              />
              <a>
                <h5 className="card__title">Cloud Native</h5>
              </a>
            </div>
          </div>
          <div className="container__col-md-3 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/key-features/secured.png"
                alt="Secured"
              />
              <a>
                <h5 className="card__title">Secured</h5>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdobeFormsTools;
