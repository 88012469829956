import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import AdobeFormsBanner from "../components/Platforms/AdobeFormsBanner/AdobeFormsBanner";
import OurProjects from "../components/Solutions/OurProjects/OurProjects";
import AdobeFormsTools from "../components/Platforms/AdobeFormsTools/AdobeFormsTools";
import AEMFormServices from "../components/Solutions/AEMFormServices/AEMFormServices";
import AEMFormsVideo from "../components/Solutions/AEMFormsVideo/AEMFormsVideo";
import { Helmet } from "react-helmet";
function AdobeForms() {
	const scrollTop = () => {
		window.scroll(0, 1);
	};
	return (
		<Layout><Helmet>
		<meta charSet="utf-8" />
		   <title>
	   Hashout Technologies - Adobe Experience Manager Forms
		   </title>
		   <meta
			 name="description"
			 content="Design, approve, and publish centrally managed forms."
		   ></meta></Helmet>
			<SolutionsHelmet />
			<AdobeFormsBanner />
			<AEMFormServices />
			<AEMFormsVideo />
			<AdobeFormsTools />
			<OurProjects />

			<LearnAboutUs page="adobe-experience-manager-forms"/>
			<a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
				<img
					className="go-to-top__img"
					src="../../solutions/go-to-top.png"
					alt="Go to top"
				/>
			</a>
		</Layout>
	);
}
export default AdobeForms;
